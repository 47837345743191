import React from "react"
import { Link } from 'gatsby'

const Footer = ({ children }) => (
	<div id='footer'>
		<footer>
          		<div style={{
          			maxWidth: 1200,
		  			    margin: '0 auto',
		  			    padding: '0px 1.0875rem',
		  			    paddingTop: 0,
		  			  }}
	         	  >
	         	 
		         <div className="container-50-50">
                <div>
                  <ul>
                    <li><Link to='/for-individuals'>Employees</Link></li>
                    <li><Link to='/for-business'>Employers</Link></li>
                    <li><Link to='/about-referrals'>Referrals</Link></li>
                    {/*<li><Link to='/resources'>Resources</Link></li>*/}
                    </ul>
                    {/*<br/>*/}
                    <ul>
                    {/*<li><Link to='/'>Home</Link></li>*/}
                    <li><Link to='/privacy'>Privacy</Link></li>
                    <li><Link to='/contact-us'>Contact Us</Link></li>
                    <li><a href='../corpeap-mgr-guide.pdf' target="_blank"> Guide for Managers &amp; Supervisors</a></li>
                  </ul>
                </div> 
                <div className='contactArea' style={{fontSize: '.8rem'}}>
                <p><a href='mailto:info@eap.help'>info@eap.help</a><br/>
                1 (978) 635-1712</p>
                </div>
                  
		         </div>
             <div style={{
               margin: '0 auto',
               paddingBottom: '20px',
               textAlign: 'center'
             }}>
             © {new Date().getFullYear()} Corporate EAP Resources
             </div>
		         
		         
	            </div>
          </footer>
	</div>
)


export default Footer
