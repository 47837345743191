import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import navStyles from './nav.module.css'

class Navigation extends React.Component {
	state = {
		isTop: true,
	};
	//constructor(props) {
	//	super(props);
	//}
	componentDidMount() {
		document.addEventListener('scroll', () => { 
			const isTop = window.scrollY < 10;
			if(isTop !== this.state.isTop){
				this.setState({isTop})
				//console.log(this);
			}
		});
	} 
	
	render () {
		return (
			<header className={this.state.isTop  ? 'top' : 'scrolled'}>
				<div className="contain">
			      <h3 style={{
							marginBottom: '0px',
							float: 'left'
						}}>
				        <Link
				          to="/"
				          style={{
				            color: `#335599`,
				            textDecoration: `none`,
				          }}
				        >
				          Corporate EAP Resources
				        </Link>
					</h3>
				    <nav style= {{
					     float: `right`,
					     color: `#335599`,
				    }}>
					    <ul>
					      <li className='item'><Link to='/for-individuals'>Employees</Link></li>
					      <li className='item'><Link to='/for-business'>Employers</Link></li>
								<li><Link to='/contact-us' className='btn'>Contact Us</Link></li>
					    </ul>
				    </nav>
				</div>	
			</header>
		)
		

	}
	
}



Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation